import { useGLTF } from '@react-three/drei'
import { RigidBody } from '@react-three/rapier'

export default function BlockEnd({
  position = [0, 0, 0],
  geometry,
  material
}) {
  const hamburguer = useGLTF('./hamburger.glb')

  hamburguer.scene.traverse(c => {
    c.castShadow = true
  })

  return <group position={position}>
    <mesh
      geometry={ geometry }
      material={ material }
      position={ [ 0, 0, 0 ] }
      scale={ [ 4, 0.2, 4 ] }
      receiveShadow
    />
    <RigidBody type='fixed' colliders='hull' position={[0, 0.5, 0]} restitution={0.2} friction={0}>
      <primitive object={ hamburguer.scene } scale={0.2}/>
    </RigidBody>
  </group>
}
