import { useMemo } from 'react'
import * as THREE from 'three'
import BlockStart from './BlockStart'
import BlockSpinner from './BlockSpinner'
import BlockLimbo from './BlockLimbo'
import BlockAxe from './BlockAxe'
import BlockEnd from './BlockEnd'
import Bounds from './Bounds'
import Player from './Player'

const boxGeometry = new THREE.BoxGeometry(1, 1, 1)

const floor1Material = new THREE.MeshStandardMaterial({ color: '#97cba9' })
const floor2Material = new THREE.MeshStandardMaterial({ color: '#dde0ab' })
const obstacleMaterial = new THREE.MeshStandardMaterial({ color: 'tomato' })
const wallMaterial = new THREE.MeshStandardMaterial({ color: '#51adcf' })
// #9fd3c7

export default function Level({ count=5, types=[BlockSpinner, BlockAxe, BlockLimbo], seed=0}) {
  const blocks = useMemo(() => {
    const blocks = []

    for (let i = 0; i < count; i++) {
      const type = types[Math.floor(Math.random() * types.length)]
      blocks.push(type)
    }

    return blocks
  }, [count, types, seed])

  return (
    <>
      <BlockStart
        geometry={boxGeometry}
        material={floor1Material}
        position={[0, 0, 0]}
      />

      <Player />

      {blocks.map((Block, index) => (
        <Block
          key={`block-${index}`}
          position={[0, 0, - (index + 1) * 4]}
          geometry={boxGeometry}
          material={floor2Material}
          obstacleMaterial={obstacleMaterial}
        />
      ))}

      <BlockEnd
        geometry={boxGeometry}
        material={floor1Material}
        position={[0, 0, - (count + 1) * 4]}
      />

      <Bounds
        geometry={boxGeometry}
        material={wallMaterial}
        length={count + 2}
      />
    </>
  )
}
