import { Physics } from '@react-three/rapier'
import Lights from './components/Lights.jsx'
import Level from './components/Level.jsx'
import BlockSpinner from './components/BlockSpinner.jsx'
import BlockAxe from './components/BlockAxe.jsx'
import BlockLimbo from './components/BlockLimbo.jsx'
import useGame from './stores/useGame.jsx'

export default function Experience()
{
    const blocksCount = useGame((state) => state.blocksCount)
    const blocksSeed = useGame((state) => state.blocksSeed)
    return <>

        <color args={['#ffebbb']} attach="background" />

        <Lights />

        <Physics debug={false}>
            <Level count={blocksCount} types={[ BlockSpinner, BlockAxe, BlockLimbo ]} seed={blocksSeed} />
        </Physics>

    </>
}