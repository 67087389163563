import { useKeyboardControls } from '@react-three/drei'
import { addEffect } from '@react-three/fiber'
import { useRef, useEffect } from 'react'
import useGame from '../stores/useGame'

export default function  Interface() {
  const forward = useKeyboardControls(state => state.forward)
  const rightward = useKeyboardControls(state => state.rightward)
  const backward = useKeyboardControls(state => state.backward)
  const leftward = useKeyboardControls(state => state.leftward)
  const jump = useKeyboardControls(state => state.jump)
  const restart = useGame(state => state.restart)
  const phase = useGame(state => state.phase)
  const time = useRef()

  useEffect(() => {
    const unsubscribeEffect = addEffect(() => {
      const state = useGame.getState()
      let elapsedTime = 0
      if(state.phase === 'playing')
        elapsedTime = (Date.now() - state.startTime)
      else if(state.phase === 'ended')
        elapsedTime = (state.endTime - state.startTime)

      elapsedTime /= 1000
      elapsedTime = elapsedTime.toFixed(2)

      if (time.current)
        time.current.textContent = elapsedTime
    })
    return () => {
      unsubscribeEffect()
    }
  }, [])

  return <div className="interface">
    <div ref={time} className="time">0.00</div>
    {phase === 'ended' && (
      <div className="restart" onClick={restart}>Restart</div>
    )}

    <div className="controls">
        <div className="raw">
            <div className={`${forward ? 'key active' : 'key'}`}></div>
        </div>
        <div className="raw">
            <div className={`${leftward ? 'key active' : 'key'}`}></div>
            <div className={`${backward ? 'key active' : 'key'}`}></div>
            <div className={`${rightward ? 'key active' : 'key'}`}></div>
        </div>
        <div className="raw">
            <div className={`${jump ? 'key large active' : 'key large'}`}></div>
        </div>
    </div>
  </div>
}